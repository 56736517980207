import _objectSpread from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/count-down/style";
import _CountDown from "vant/es/count-down";

var _components;

import "core-js/modules/es.function.name.js";
import { defineComponent, computed, inject } from 'vue';
import { useStore } from 'vuex';
import SvgIcon from '@/components/share/SvgIcon.vue';
import { BOOKING_STATUS, ORDER_STATUS, PAYMENT_STATUS } from '@/constants';
export default defineComponent({
  name: 'OrderStatus',
  components: (_components = {}, _defineProperty(_components, _CountDown.name, _CountDown), _defineProperty(_components, "SvgIcon", SvgIcon), _components),
  setup: function setup() {
    var t = inject('t');
    var store = useStore();
    var booking = computed(function () {
      return store.state.booking.bookings.booking;
    });

    var _bookingStatus = computed(function () {
      var _booking$value;

      return (_booking$value = booking.value) === null || _booking$value === void 0 ? void 0 : _booking$value.status;
    });

    var _paymentStatus = computed(function () {
      var _booking$value2;

      return (_booking$value2 = booking.value) === null || _booking$value2 === void 0 ? void 0 : _booking$value2.payment_status;
    });

    var paymentType = computed(function () {
      return store.state.booking.booking_info.hotel.payment_type || booking.value.payment_type;
    });

    var getOrderStatus = function getOrderStatus(paymentType, _bookingStatus, _paymentStatus) {
      switch (true) {
        case _bookingStatus === BOOKING_STATUS.CANCELED:
          return ORDER_STATUS.CANCELED;

        case _bookingStatus === BOOKING_STATUS.CHECKED_OUT:
          return ORDER_STATUS.CHECKED_OUT;

        case _paymentStatus === PAYMENT_STATUS.PAID_IN_PART || _paymentStatus === PAYMENT_STATUS.UNPAID:
          return ORDER_STATUS.UNPAID;

        case _paymentStatus === PAYMENT_STATUS.REFUNDING:
          return ORDER_STATUS.REFUNDING;

        case _paymentStatus === PAYMENT_STATUS.PAID_IN_FULL:
        case paymentType === 1:
          return ORDER_STATUS.SUCCESS;
      }
    };

    var orderStatus = computed(function () {
      return getOrderStatus(paymentType.value, _bookingStatus.value, _paymentStatus.value);
    });

    var getTitle = function getTitle(orderStatus) {
      switch (orderStatus) {
        case ORDER_STATUS.SUCCESS:
          return {
            title: t('order.booking_done'),
            info: t('order.your_payment_completed_have_a_nice_trip')
          };

        case ORDER_STATUS.CANCELED:
          return {
            title: t('order.the_booking_has_been_cancelled'),
            info: t('order.what_a_pity_hope_to_see_you_again')
          };

        case ORDER_STATUS.CHECKED_OUT:
          return {
            title: t('order.checked_out'),
            info: '期待您下次再預訂！'
          };
      }
    };

    var text = computed(function () {
      return _objectSpread({}, getTitle(orderStatus.value));
    });
    return {
      t: t,
      orderStatus: orderStatus,
      ORDER_STATUS: ORDER_STATUS,
      text: text
    };
  }
});