import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1d457668"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "booking-price"
};
var _hoisted_2 = {
  class: "dialog-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_rules_of_accommodation = _resolveComponent("rules-of-accommodation");

  var _component_dialog_component = _resolveComponent("dialog-component");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [!_ctx.isMobile ? (_openBlock(), _createBlock(_component_dialog_component, {
    key: 0,
    open: _ctx.isRulesOfAccommodationPopupOpen,
    title: _ctx.t('order.rules_of_accommodation'),
    width: 1200,
    onClose: _ctx.onRulesOfAccommodationPopupClose
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_createVNode(_component_rules_of_accommodation, {
        hotel: _ctx.hotel
      }, null, 8, ["hotel"])])];
    }),
    _: 1
  }, 8, ["open", "title", "onClose"])) : _createCommentVNode("", true)]);
}