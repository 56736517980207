import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/count-down/style";
import _CountDown from "vant/es/count-down";
import "core-js/modules/es.function.name.js";
import { defineComponent, inject, ref, computed } from 'vue';
import { useStore } from 'vuex';
import Dialog from '@/components/share/Dialog.vue';
import router from '@/router';
import { parse, differenceInMilliseconds } from 'date-fns';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'CountDowmTimer',
  components: _defineProperty({
    SvgIcon: SvgIcon,
    DialogComponent: Dialog
  }, _CountDown.name, _CountDown),
  setup: function setup() {
    var store = useStore();
    var isTimeoutModelOpen = ref(false);
    var isOrderError = ref(false);
    var t = inject('t');
    var isMobile = inject('isMobile');
    var locale = inject('locale');
    var time = computed(function () {
      if (store.state.booking.bookings.expired_ts) {
        var date = parse(store.state.booking.bookings.expired_ts, 't', new Date());
        return differenceInMilliseconds(date, new Date());
      } else {
        return 1000 * 60 * 60 * 20;
      }
    });

    var onFinish = function onFinish() {
      isTimeoutModelOpen.value = true;
    };

    var returnHomePage = function returnHomePage() {
      router.push({
        name: 'Home',
        params: {
          locale: locale.value
        }
      });
    };

    return {
      t: t,
      time: time,
      isTimeoutModelOpen: isTimeoutModelOpen,
      isMobile: isMobile,
      onFinish: onFinish,
      returnHomePage: returnHomePage,
      isOrderError: isOrderError
    };
  }
});