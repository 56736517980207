import { computed, defineComponent, inject } from 'vue';
import Dialog from '@/components/share/Dialog.vue';
import RulesOfAccommodation from '@/components/hotel/RulesOfAccommodation.vue';
import { useStore } from 'vuex';
export default defineComponent({
  components: {
    DialogComponent: Dialog,
    RulesOfAccommodation: RulesOfAccommodation
  },
  props: ['isRulesOfAccommodationPopupOpen', 'onRulesOfAccommodationPopupClose', 'hotel'],
  setup: function setup() {
    var t = inject('t');
    var isMobile = inject('isMobile');
    var store = useStore();
    var booking = computed(function () {
      return store.state.booking.bookings.booking;
    });
    return {
      t: t,
      isMobile: isMobile,
      booking: booking
    };
  }
});