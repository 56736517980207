import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-d3a299ee"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "dialog-content"
};
var _hoisted_2 = {
  class: "box"
};
var _hoisted_3 = {
  class: "price-table"
};
var _hoisted_4 = {
  class: "total-row"
};
var _hoisted_5 = {
  class: "cancel-row"
};
var _hoisted_6 = {
  class: "refund-row"
};
var _hoisted_7 = {
  class: "title"
};
var _hoisted_8 = {
  class: "selector-wrapper"
};
var _hoisted_9 = {
  value: "",
  disabled: "",
  selected: ""
};
var _hoisted_10 = ["value", "selected"];
var _hoisted_11 = ["placeholder"];
var _hoisted_12 = {
  class: "info"
};
var _hoisted_13 = {
  class: "btn-group"
};
var _hoisted_14 = ["disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_dialog_component = _resolveComponent("dialog-component");

  return _openBlock(), _createBlock(_component_dialog_component, {
    open: _ctx.isCancelPopupOpen,
    title: _ctx.t('order.booking_to_be_cancelled'),
    width: _ctx.isMobile ? 'calc(100% - 32px)' : 600,
    onClose: _ctx.onCancelPopupClose
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.booking.cancel_rule), 1), _createElementVNode("table", _hoisted_3, [_createElementVNode("tr", _hoisted_4, [_createElementVNode("td", null, _toDisplayString(_ctx.t('order.real_payment_amount')), 1), _createElementVNode("td", null, "NT$ " + _toDisplayString(_ctx.booking.price), 1)]), _createElementVNode("tr", _hoisted_5, [_createElementVNode("td", null, _toDisplayString(_ctx.t('order.cancellation_bill_amount')), 1), _createElementVNode("td", null, "NT$ " + _toDisplayString(_ctx.booking.cancellation_fee), 1)]), _createElementVNode("tr", _hoisted_6, [_createElementVNode("td", null, _toDisplayString(_ctx.t('order.refund_amount')), 1), _createElementVNode("td", null, "NT$ " + _toDisplayString(_ctx.booking.cancellation_refund), 1)])]), _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t('order.cause_of_cancellation')), 1), _createElementVNode("div", _hoisted_8, [_withDirectives(_createElementVNode("select", {
        class: "selector",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _ctx.selectReason = $event;
        })
      }, [_createElementVNode("option", _hoisted_9, _toDisplayString(_ctx.t('order.please_enter_the_cause_of_cancellation')), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reasons, function (reason) {
        return _openBlock(), _createElementBlock("option", {
          key: reason.value,
          value: reason.value,
          selected: _ctx.selectReason === reason.value
        }, _toDisplayString(reason.label), 9, _hoisted_10);
      }), 128))], 512), [[_vModelSelect, _ctx.selectReason]]), _createVNode(_component_svg_icon, {
        class: "caret-down-icon",
        iconName: "icon_24_arrow_caret_down"
      })]), !_ctx.selectReason ? _withDirectives((_openBlock(), _createElementBlock("input", {
        key: 0,
        type: "text",
        class: "input-reason",
        placeholder: _ctx.t('order.please_enter_a_cause_optional'),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return _ctx.inputReason = $event;
        })
      }, null, 8, _hoisted_11)), [[_vModelText, _ctx.inputReason]]) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.t('order.the_prepaid_deposit_will_be_deducted_from_the_credit_card_account_within_21_working_days_for_any_refund_meeting_the_terms_and_conditions_of_refunds_due_to_cancelled_altered_bookings_or_early_check_outs')), 1), _createElementVNode("div", _hoisted_13, [_createElementVNode("button", {
        class: "sub-btn",
        onClick: _cache[2] || (_cache[2] = //@ts-ignore
        function () {
          return _ctx.onCancelPopupClose && _ctx.onCancelPopupClose.apply(_ctx, arguments);
        })
      }, _toDisplayString(_ctx.t('order.keep_the_booking')), 1), _createElementVNode("button", {
        class: "main-btn",
        onClick: _cache[3] || (_cache[3] = //@ts-ignore
        function () {
          return _ctx.handelCancel && _ctx.handelCancel.apply(_ctx, arguments);
        }),
        disabled: !_ctx.selectReason && !_ctx.inputReason
      }, _toDisplayString(_ctx.t('order.booking_to_be_cancelled')), 9, _hoisted_14)])])];
    }),
    _: 1
  }, 8, ["open", "title", "width", "onClose"]);
}